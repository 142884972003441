import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import request from 'lib/request';
import { ReactComponent as LoaderIcon } from 'assets/images/loader.svg';
import axios from 'axios';
import './CsvImport.scss';
import ProgressBar from 'components/SystemComponents/ProgressBar';

const checkIsImporting = (importProgressData) => {
  let output = false;

  if (importProgressData) {
    const { totalCount, successCount, warningCount, failedCount } = importProgressData;
    if (totalCount > 0 && (successCount > 0 || warningCount > 0 || failedCount > 0)) {
      output = true;
    }
  }

  return output;
}

const getProcessedCount = (importProgressData) => {
  const rslt =
    (importProgressData?.failedCount ?? 0)
    + (importProgressData?.warningCount ?? 0)
    + (importProgressData?.successCount ?? 0);

  return isFinite(rslt) && rslt >= 0 ? rslt : 0;
}

const getProgressPercentages = (importProgressData) => {
  const counts = [importProgressData?.failedCount ?? 0, importProgressData?.warningCount ?? 0, importProgressData?.successCount ?? 0];

  let totalCount = Math.max((importProgressData?.totalCount ?? 0), (counts[0] + counts[1] + counts[2]));

  const percentList = counts.map(v => v > 0 && totalCount > 0 ? (v / totalCount * 100).toFixed(1) : 0);

  return percentList;
}

const calcSpeed = (list, i1, i2) => {
  const timeDiff = list[i2].time - list[i1].time;
  const itemDiff = list[i2].items - list[i1].items;
  return timeDiff > 0 ? itemDiff / timeDiff : 0;
};

function StateAnalyzing({ type, onFinished }) {
  const [importProgressData, setImportProgressData] = useState(null);

  const speedData = useRef([]);

  const updateSpeedData = useCallback((processedCount) => {
    const tList = speedData.current;

    tList.push({
      time: Date.now() / 1000,
      items: processedCount,
    });

    if (tList.length > 10)
      tList.shift();
  }, []);

  const getAvgSpeed = useCallback(() => {
    const tList = speedData.current
    // We need min 2 points to calc speed and min 3 to avg between 2 pointst to prevent initial number jumps
    if (tList?.length < 3) return 0;

    let speeds = [];

    for (let i = 1; i < tList?.length; i++) {
      speeds.push(calcSpeed(tList, i - 1, i));
    }

    const avgSpeed = speeds.reduce((acc, speed) => acc + speed, 0) / speeds.length;
    return avgSpeed;
  }, []);

  useEffect(() => {
    let ticker;
    let errorsCount = 0;
    // true if component is unmounted and ignore request responses
    let unmounted = false;
    const cancelRequest = axios.CancelToken.source();
    let isFinished = false;

    function checkUploadStatus() {
      request
        .get(`/imports/job-status?jobType=${type}${isFinished ? '' : '&isSummary=true'}`, {
          cancelToken: cancelRequest.token,
        })
        .then((data) => {
          if (unmounted) {
            return;
          }
          if (data?.status === 'finished') {
            if (!isFinished) {
              isFinished = true;
              ticker = setTimeout(checkUploadStatus, 2000);
            } else {
              onFinished(data);
            }
          } else if (data?.status === 'active') {
            const newImportProgressData = {
              status: data.status ?? null,
              totalCount: data?.totalCount ?? 0,
              successCount: data?.successCount ?? 0,
              warningCount: data?.warningCount ?? 0,
              failedCount: data?.failedCount ?? 0,
              processedCount: 0,
              processedSpeed: 0,
              isImporting: false,
            };

            newImportProgressData.processedCount = getProcessedCount(newImportProgressData);

            newImportProgressData.isImporting = checkIsImporting(newImportProgressData);

            updateSpeedData(newImportProgressData.processedCount);

            newImportProgressData.processedSpeed = getAvgSpeed();

            setImportProgressData(newImportProgressData);

            // check for status changes in 2 seconds
            ticker = setTimeout(checkUploadStatus, 2000);
          } else {
            onFinished();
          }
        })
        .catch(() => {
          if (unmounted) {
            return;
          }
          ++errorsCount;
          // try 2 times to get data
          if (errorsCount < 2) {
            ticker = setTimeout(checkUploadStatus, 3000);
          } else {
            onFinished();
          }
        });
    }

    checkUploadStatus();
    return () => {
      unmounted = true;
      cancelRequest.cancel();
      if (ticker) {
        clearTimeout(ticker);
      }
    };
  }, [updateSpeedData, onFinished, type, getAvgSpeed]);

  const isAnalyzing = !importProgressData?.isImporting && importProgressData?.status === 'active';

  return (
    <div className='stateAnalyzing'>
      {(isAnalyzing) && (
        <>
          <div className='loader'>
            <LoaderIcon />
          </div>
          <div>Analyzing file...</div>
        </>
      )}
      {(importProgressData?.isImporting) && (
        <>
          <div className='progressContainer'>
            <ProgressBar percent={getProgressPercentages(importProgressData)} />
          </div>
          <div className='progress-info'>
            {/* // TODO: Hide total count until we fix inconsistencies there */}
            {/* <strong>{importProgressData?.processedCount}</strong> of <strong>{importProgressData?.totalCount}</strong> items */}
            <strong>{importProgressData?.processedCount}</strong> items processed
            (<strong>{importProgressData?.successCount}</strong> imported{
              importProgressData?.warningCount > 0 && (
                <>,<strong>{importProgressData?.warningCount}</strong> warnings</>
              )
            }{
              importProgressData?.failedCount > 0 && (
                <>, <strong>{importProgressData?.failedCount}</strong> errors</>
              )
            })
            {importProgressData?.processedSpeed >= 0.1 && (
              <>,{' '}<strong>{importProgressData?.processedSpeed?.toFixed(importProgressData?.processedSpeed < 2 ? 1 : 0)}</strong> items per second</>
            )}
          </div>
        </>
      )}
    </div>
  );
}

StateAnalyzing.propTypes = {
  type: PropTypes.string.isRequired,
  onFinished: PropTypes.func.isRequired,
};

export default StateAnalyzing;
