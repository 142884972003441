import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { bulkPlansToDetailState } from './dataTransform';
import {
  reducer,
  initialState,
  INSPECTION_PLANS_BULK_RESULTS_ACTIONS,
  RESULT_STATUS,
} from './inspectionPlansBulkResultsReducer';
import inspectionPlansService from './inspectionPlansService';
import { RESULT_FILTER } from 'modules/inspection-plans/inspectionPlansBulkResultsReducer';

const RESULT_FILTER_CONFIG = {
  [RESULT_FILTER.ALL]: () => true,
  [RESULT_FILTER.SUCCESS]: (status) => status === RESULT_FILTER.SUCCESS,
  [RESULT_FILTER.FAILED]: (status) => status === RESULT_FILTER.FAILED,
  [RESULT_FILTER.SKIPPED]: (status) => status === RESULT_FILTER.SKIPPED,
  [RESULT_FILTER.NO_TAGS]: (status) => status === RESULT_FILTER.NO_TAGS,
  [RESULT_FILTER.USER_PLAN]: (status) => status === RESULT_FILTER.USER_PLAN,
  [RESULT_FILTER.AUTO_PLAN]: (status) => status === RESULT_FILTER.AUTO_PLAN,
  [RESULT_FILTER.NEEDS_REVIEW]: (status) =>
    status === RESULT_FILTER.NEEDS_REVIEW,
};

const useInspectionPlansBulkResults = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    inspectionPlansService
      .getBulkResults()
      .then((res) =>
        dispatch({
          type: INSPECTION_PLANS_BULK_RESULTS_ACTIONS.APP_LOADS_DATA,
          payload: bulkPlansToDetailState(res),
        }),
      )
      .catch(() =>
        dispatch({
          type: INSPECTION_PLANS_BULK_RESULTS_ACTIONS.SET_ERRORS,
          payload: ['Could not load bulk inspection results'],
        }),
      );
  }, []);

  const userFiltersAssets = useCallback(
    (status) =>
      dispatch({
        type: INSPECTION_PLANS_BULK_RESULTS_ACTIONS.USER_FILTERS_ASSET,
        payload: status,
      }),
    [dispatch],
  );

  const userFiltersSources = useCallback(
    (status) =>
      dispatch({
        type: INSPECTION_PLANS_BULK_RESULTS_ACTIONS.USER_FILTERS_SOURCE,
        payload: status,
      }),
    [dispatch],
  );

  const assets = useMemo(
    () =>
      state.assets
        .map((asset) => ({
          ...asset,
          types: (asset.types || []).filter((type) =>
            RESULT_FILTER_CONFIG[state.assetFilter](type.status),
          ),
        }))
        .filter((asset) => asset.types.length),
    [state.assets, state.assetFilter],
  );

  const sources = useMemo(
    () =>
      state.sources
        .map((source) => ({
          ...source,
          types: (source.types || []).filter((type) =>
            RESULT_FILTER_CONFIG[state.sourceFilter](type.status),
          ),
        }))
        .filter((source) => source.types.length),
    [state.sources, state.sourceFilter],
  );

  const showAssetResults = !!Object.values(RESULT_STATUS).reduce(
    (sum, status) => sum + (state.assetTotal[status] ?? 0),
    0,
  );

  const showSourceResults = !!Object.values(RESULT_STATUS).reduce(
    (sum, status) => sum + (state.sourceTotal[status] ?? 0),
    0,
  );

  return {
    state,
    assets,
    sources,
    showAssetResults,
    showSourceResults,
    userFiltersAssets,
    userFiltersSources,
  };
};

export default useInspectionPlansBulkResults;
