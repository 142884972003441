import useInspectionPlansBulkResults from 'modules/inspection-plans/useInspectionPlanBulkResults';

import BulkResultsHeader from '../bulk-results-header/BulkResultsHeader';
import FilterTabs from './FilterTabs';
import PlanResult from './PlanResult';

import './InspectionPlansBulkResultsPage.scss';

const ResourceList = ({ data }) => (
  <div className='results-list'>
    {data.map((resource) => (
      <PlanResult
        title={resource.name}
        key={resource.name}
        link={resource.link}
      >
        {resource.types.map((type) => (
          <PlanResult.Item
            key={type.name}
            name={type.name}
            status={type.status}
            link={type.link}
          />
        ))}
      </PlanResult>
    ))}
  </div>
);

const InspectionPlansBulkResultsPage = () => {
  const {
    state,
    assets,
    sources,
    showAssetResults,
    showSourceResults,
    userFiltersAssets,
    userFiltersSources,
  } = useInspectionPlansBulkResults();

  if (state.loading) {
    return null;
  }

  return (
    <div className='inspection-plans-bulk-results-page container-m'>
      <div className='row'>
        <h2>Inspection plans</h2>
      </div>
      <div className='row results-header'>
        <BulkResultsHeader
          totals={{
            success: state.assetTotal.success + state.sourceTotal.success,
            skipped: state.assetTotal.skipped + state.sourceTotal.skipped,
            failed: state.assetTotal.failed + state.sourceTotal.failed,
          }}
        />
      </div>
      <div className='row'>
        <h4>Asset inspection plans</h4>
        {showAssetResults ? (
          <>
            <FilterTabs
              onChange={userFiltersAssets}
              currentTab={state.assetFilter}
              totals={state.assetTotal}
            />
            <ResourceList data={assets} />
          </>
        ) : (
          <p className='empty-plan-list'>No asset inspection plans available</p>
        )}
      </div>
      <div className='row'>
        <h4>Source inspection plans</h4>
        {showSourceResults ? (
          <>
            <FilterTabs
              onChange={userFiltersSources}
              currentTab={state.sourceFilter}
              totals={state.sourceTotal}
            />
            <ResourceList data={sources} />
          </>
        ) : (
          <p className='empty-plan-list'>
            No source inspection plans available
          </p>
        )}
      </div>
    </div>
  );
};

export default InspectionPlansBulkResultsPage;
